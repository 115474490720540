import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";

const ApiClient = axios.create({ baseURL: "https://api.bergregions.pl/api/" });

interface Order {
  ordersInPreparation: string[];
  ordersForPickUp: string[];
  placeDetailsVM?: {
    name?: string;
    logoUrl?: string;
  };
}

const mockOrders: Order = {
  ordersInPreparation: [
    "888",
    "889",
    "890",
    "891",
    "892",
    "893",
    "894",
    "895",
    "896",
    "897",
    "898",
    "899",
    "900",
    "901",
    "902",
    "903",
    "904",
    "905",
    "1000",
    "2000",
    "3000",
  ],
  ordersForPickUp: [
    "801",
    "802",
    "803",
    "804",
    "805",
    "806",
    "807",
    "808",
    "809",
    "810",
    "811",
    "812",
    "813",
    "814",
    "815",
  ],
  placeDetailsVM: {
    name: "Restauracja Testowa",
  },
};

function App() {
  const [data, setData] = useState<Order | undefined>(undefined);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const urlParams = new URLSearchParams(window.location.search);
  const appId = urlParams.get("appId");

  useEffect(() => {
    const fetchData = async () => {
      if (!appId) return;
      try {
        const response = await ApiClient.get(`v1/qsr/${appId}/kds`);
        if (response.data) {
          if (JSON.stringify(response.data) !== JSON.stringify(data)) {
            setData(response.data);
          }
        }
      } catch (error) {
        console.error("Wystąpił problem podczas pobierania danych:", error);

        if (!data) {
          setData(mockOrders);
        }
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000);
    return () => clearInterval(intervalId);
  }, [appId, data]);

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const { boxSize, gridColumnsPrep, gridColumnsPickUp } = useMemo(() => {
    const headerHeight = 200;
    const footerHeight = 50;
    const padding = 32;
    const gap = 8;
    const minBoxSize = 60;
    const maxBoxSize = 130;
    const isVertical = dimensions.height > dimensions.width;

    const availableHeight =
      dimensions.height - headerHeight - footerHeight - padding * 2;
    const availableWidth = dimensions.width - padding * 2;

    const calculateGridDimensions = (
      orderCount: number,
      availableSpace: number
    ) => {
      let columns = Math.ceil(Math.sqrt(orderCount));
      let boxSize = Math.min(availableSpace / columns, maxBoxSize);

      while (boxSize < minBoxSize && columns > 1) {
        columns--;
        boxSize = Math.min(availableSpace / columns, maxBoxSize);
      }

      return { columns, boxSize: Math.max(boxSize, minBoxSize) };
    };

    let gridColumnsPrep, gridColumnsPickUp, boxSize;

    if (isVertical && data) {
      const prepDimensions = calculateGridDimensions(
        data.ordersInPreparation.length,
        availableWidth
      );
      const pickUpDimensions = calculateGridDimensions(
        data.ordersForPickUp.length,
        availableWidth
      );

      gridColumnsPrep = prepDimensions.columns;
      gridColumnsPickUp = pickUpDimensions.columns;
      boxSize = Math.min(prepDimensions.boxSize, pickUpDimensions.boxSize);
    } else if (!isVertical && data) {
      const halfWidth = (availableWidth - gap) / 2;
      const prepDimensions = calculateGridDimensions(
        data.ordersInPreparation.length,
        halfWidth
      );
      const pickUpDimensions = calculateGridDimensions(
        data.ordersForPickUp.length,
        halfWidth
      );

      gridColumnsPrep = prepDimensions.columns;
      gridColumnsPickUp = pickUpDimensions.columns;
      boxSize = Math.min(prepDimensions.boxSize, pickUpDimensions.boxSize);
    }

    return { boxSize, gridColumnsPrep, gridColumnsPickUp };
  }, [dimensions, data]);

  const calculateFontSize = (text: string, boxSize: number) => {
    const maxWidth = boxSize * 0.9;
    const maxHeight = boxSize * 0.9;
    let fontSize = boxSize;

    const textFits = (size: number) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (context) {
        context.font = `${size}px sans-serif`;
        const metrics = context.measureText(text);
        return metrics.width <= maxWidth && size <= maxHeight;
      }
      return false;
    };

    while (fontSize > 1) {
      if (textFits(fontSize)) {
        break;
      }
      fontSize = fontSize / 2;
    }

    let low = fontSize;
    let high = fontSize * 2;

    while (high - low > 1) {
      const mid = Math.floor((low + high) / 2);
      if (textFits(mid)) {
        low = mid;
      } else {
        high = mid;
      }
    }

    return low;
  };

  const renderOrders = (orders: string[], isDone: boolean) => {
    // Obliczamy optymalną szerokość kontenera
    const containerStyle: React.CSSProperties = {
      display: "flex",
      flexWrap: "wrap",
      gap: "1rem",
      justifyContent: "center",
      maxWidth: "100%",
      padding: "1rem",
    };

    // Style dla pojedynczego kwadratu
    const getBoxStyle = () => ({
      width: `${boxSize}px`,
      height: `${boxSize}px`,
      position: "relative" as const,
      backgroundColor: isDone ? "#9B6547" : "transparent",
      border: isDone ? "none" : "1px solid white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: 0,
      flexGrow: 0,
      margin: "0.5rem",
    });

    return (
      <div style={containerStyle}>
        {data &&
          boxSize &&
          orders.map((order, index) => (
            <div key={`${order}-${index}`} style={getBoxStyle()}>
              <div
                style={{
                  fontSize: `${calculateFontSize(order, boxSize)}px`,
                  lineHeight: 1,
                }}
              >
                {order}
              </div>
            </div>
          ))}
      </div>
    );
  };

  if (!appId) {
    return (
      <main className="flex min-h-screen items-center justify-center">
        <p className="text-4xl text-red-600">Brak parametru appId w URL</p>
      </main>
    );
  }

  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "black",
        color: "white",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          marginTop: "1rem",
          marginBottom: "1rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        {data?.placeDetailsVM?.logoUrl && (
          <img
            src={data?.placeDetailsVM?.logoUrl}
            alt="logo"
            style={{ maxWidth: "21%", maxHeight: "120px" }}
          />
        )}
        {data?.placeDetailsVM?.name && (
          <div
            style={{
              alignSelf: "center",
              fontSize: dimensions.width > 768 ? "3.75rem" : "2.25rem",
            }}
          >
            {data?.placeDetailsVM?.name}
          </div>
        )}
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection:
            dimensions.height > dimensions.width ? "column" : "row",
          padding: "1rem",
          gap: "2rem",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              fontSize: dimensions.width > 768 ? "3rem" : "1.875rem",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            W realizacji
          </h2>
          {data && renderOrders(data.ordersInPreparation, false)}
        </div>

        {/* Separator */}
        {dimensions.height > dimensions.width ? (
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "white",
              margin: "1.75rem 0",
            }}
          />
        ) : (
          <div
            style={{
              width: "1px",
              backgroundColor: "white",
              margin: "0 1.75rem",
              alignSelf: "stretch",
              maxHeight: "85%",
            }}
          />
        )}

        <div
          style={{
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              fontSize: dimensions.width > 768 ? "3rem" : "1.875rem",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            Do odbioru
          </h2>
          {data && renderOrders(data.ordersForPickUp, true)}
        </div>
      </div>

      <img
        src="/logoBergRegions.svg"
        alt="Logo Berg Regions"
        style={{
          position: "fixed",
          bottom: "1%",
          right: "45%",
          width: "8%",
          opacity: 0.8,
        }}
      />
    </main>
  );
}

export default App;
